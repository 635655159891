import React, {useEffect} from 'react';
import './App.css';
import "./i18n/i18n";
import {withTranslation} from "react-i18next";
import {firebaseAnalitics} from "./firebaseConfig";
import Main from "./components/Main/Main";
import {Route, Switch} from 'react-router-dom';
import TermsOfUse from "./components/TermOfUse/TermsOfUse";
import Privacy from "./components/Privacy/Privacy";

const WithTranslationMain = withTranslation()(Main);

function App() {
    useEffect(() => {
        firebaseAnalitics.logEvent('page_visited')
    }, [])
    return (
        <div className="app">
            <Switch>
                <Route path={'/privacy'} render={() => <Privacy/>}/>
                <Route path={'/termsofuse'} render={() => <TermsOfUse/>}/>
                <Route exact path={'/'} render={() => <WithTranslationMain/>}/>
            </Switch>
        </div>
    );
}

export default App