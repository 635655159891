import firebase from "firebase";
import 'firebase/analytics'

export const firebaseConfig = {
    apiKey: "AIzaSyBMx2MpNNdtysXfkjyCUP4pBlYPT45KFow",
    authDomain: "training-32271.firebaseapp.com",
    databaseURL: "https://training-32271.firebaseio.com",
    projectId: "training-32271",
    storageBucket: "training-32271.appspot.com",
    messagingSenderId: "359721207121",
    appId: "1:359721207121:web:cbfa107fac2dd102b1bf70",
    measurementId: "G-C3C87HSJ3X"
};

firebase.initializeApp(firebaseConfig)
export const firebaseAnalitics = firebase.analytics()