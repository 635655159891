import React from 'react'
import styles from './Main.module.css'
import Link from './Link/Link';
import googlePlay from '../../assets/img/app_google.png'
import gp from '../../assets/img/gp.png'
import app_store from '../../assets/img/app_store.svg'
import { useTranslation } from 'react-i18next';

const Main = () => {
    const [t, i18n] = useTranslation();
    return (
        <main className={styles.main}>
            <div className={styles.titles}>
                <h1>{t('Six pack in 30 days')}</h1>
                <h3>{t('Get a strong abs shortly')}</h3>
            </div>
            <div className={styles.links}>
                {/*<Link img={googlePlay} type="google" link={'https://sd4f4.app.link/main_play_store'} />*/}
                {/*<Link img={gp} type="google" link={'https://sd4f4.app.link/main_play_store'} />*/}
                <Link img={app_store} type="app store" link={'https://apps.apple.com/us/app/id1560127980'} />
            </div>
        </main>
    )
}
export default Main;