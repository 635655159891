import React from 'react'
import styles from './TermsOfUse.module.css';
import {useTranslation, Trans} from 'react-i18next';
import {NavLink} from 'react-router-dom';

const TermsOfUse = () => {
    const [t, i18n] = useTranslation();
    return (
        <main className={styles.main}>
            <div>
                <h1>
                    {t('Terms Of Use')}
                </h1>
                <p>
                    {t('Terms Of Use Subtitle')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Introduction')}
                </h3>
                <p>
                    {t('Introduction Details')}
                </p>
            </div>

            <div>
                <h3>
                    {t('Accept Terms And Conditions')}
                </h3>
                <p>
                    {t('Accept Terms And Conditions Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Intellectual Property')}
                </h3>
                <p>
                    {t('Intellectual Property Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Legality of Use')}

                </h3>
                <p>
                    {t('Legality of Use Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Membership Subscriptions')}
                </h3>
                <p>
                    {t('Membership Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Representation Warranties')}
                </h3>
                <p>
                    {t('Representation Warranties Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Service Restrictions')}
                </h3>
                <p>
                    {t('Service Restrictions Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Privacy Policy')}
                </h3>
                <p>
                    <Trans i18nKey={'Privacy Policy Terms'}>
                        Please see the <NavLink to={'/privacy'}>$t(Privacy Policy)</NavLink>. Please bear in mind that we might refuse or limit your access to our Products in case you refuse to accept the <NavLink to={'privacy'}>$t(Privacy Policy)</NavLink>. If you have a membership subscription and you do not accept or refuse to accept any new Privacy Policy that we might release in the future, and that is not decreasing any of your existing rights, we might refuse or limit your access to our Products without compensating any fees paid for your membership subscription. You can still use our Products once you accept said new Privacy Policy. Your account will be put on hold, and no further automatic renewals of your subscription will be possible. We will need your active acceptance only in the case we materially change our Privacy Policy due to legal requirements or in the course of compliance procedures (for example, if we amend the types of personal data we collect from you, your rights, or change data processing purposes). For any technical or non-material changes, your continued use of our Products will constitute a valid consent with such new <NavLink to={'/privacy'}>$t(Privacy Policy)</NavLink>.
                    </Trans>
                </p>
            </div>
            <div>
                <h3>
                    {t('Maintenance')}
                </h3>
                <p>
                    {t('Maintenance Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Content Created by Users and Rights to Such Content.')}
                </h3>
                <p>
                    {t('Content Created Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Territory')}
                </h3>
                <p>
                    {t('Territory Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Governing Law')}
                </h3>
                <p>
                    {t('Governing Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Use By Minors')}
                </h3>
                <p>
                    {t('Use Minors Details')}
                    <a href={'mailto:smartsharkmov@gmail.com'}>smartsharkmov@gmail.com</a>
                    {t('Use Minors Details 2')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Disclaimer of Warranties')}
                </h3>
                <p>
                    {t('Warranties Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Limitations of Liability')}
                </h3>
                <p>
                    {t('Limitations Details')}
                </p>
            </div>
            <div>
                <h3>
                    {t('Contact us')}
                </h3>
                <p>
                    {t('Contact Us Details')}
                    <a href={'mailto:smartsharkmov@gmail.com'}>smartsharkmov@gmail.com</a>
                    {t('Contact Us Details 2')}
                </p>
            </div>
            {/*<NavLink to={'/privacy'}>privacy</NavLink>*/}
        </main>
    )
}
export default TermsOfUse;